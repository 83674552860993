@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter&family=Lato:ital,wght@1,900&family=PT+Serif:ital,wght@0,700;1,700&family=Roboto&display=swap');

*{
  scroll-behavior: smooth;
}

body{
  font-family: 'Roboto', sans-serif;
  scroll-behavior: smooth;

  }

  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -moz-font-feature-settings: 'liga';
    -moz-osx-font-smoothing: grayscale;
  }

  @font-face {
    font-family: 'Material Symbols Outlined';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialsymbolsoutlined/v107/kJF1BvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oDMzByHX9rA6RzaxHMPdY43zj-jCxv3fzvRNU22ZXGJpEpjC_1v-p_4MrImHCIJIZrDCvHOej.woff2) format('woff2');
  }
   
.material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}

  @keyframes draw {
    to {
      stroke-dashoffset: 0;
    }
  }
  
  .draw-line,
  .draw-path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: draw 3s linear forwards;
  }

  
  /* .box{
   margin-left: 20px;
   height: 300px;
  }
  .box img{
    display: block;
    transition: 1s;
  }
  .box:hover img{
    transform: scale(1);
    border: 5px solid;
    z-index: 2;
  }
  svg rect{
    fill: black;
    mask:url(#mask)
  }
  svg > rect{
    fill: black;
  } */
  
  .main{
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-image: url();
    background-size: cover;
    background-position: center;
  }
  .clip_video{
    -webkit-clip-path:url(#text-overlay);
    clip-path: url(#text-overlay);
    width: 100%;
  }
  svg{
    display: block;
    position: absolute;
  }

 
  #title{
    width: 600px;
  }
  .clip_video1{
    -webkit-clip-path:url(#text-overlay1);
    clip-path: url(#text-overlay1);
    width: 300px;
  }
  body {
    --main:#E8372C;
    --black: #000000;
    --ash-black: #222;
    --white: #fafafa;
    --sky: #00ccff;
    --green: #22dddd;
    --blue: #1300ff;
    --dusk: #6600ff;
    --purple: #9900ff;
    --pink: #ff0066;
    --red: #fe0222;
    --orange: #fd7702;
    --yellow: #ffbb00;
  
    --background: var(--ash-black);
    --accent: var(--main);
  
  
  }
  
  ::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: var(--background);
    -webkit-border-radius: 1ex;
  }
  
  ::-webkit-scrollbar-thumb {
    background: var(--accent);
    -webkit-border-radius: 1ex;
  }
  
  ::-webkit-scrollbar-corner {
    background: #fff3;
  }
  /* .over-lay{
    position: absolute;
    width: 100%;
    height: 100vh;
    background:linear-gradient(90deg, #aaaaaa 50%,#E8372C 50%) ;
    z-index: 1;
    top: 0%;
  } */
  .button{
    margin-top: 40px;
    width: 200px;
    position: relative;
    background: #E8372C;
    color: #fff;
    padding: .9rem 2.20rem;
    border-radius: 3rem;
    transition: .4s;
    z-index: 10;
  }
  .button::after{
    position: absolute;
    content: '';
    width: 80%;
    height: 40%;
    background: #E8372C;
    left: 0;right: 0;bottom: -4px;
    margin:0 auto;
    border-radius: 3rem;
    filter: blur(12px);
   opacity: 0;
   transition: opacity .4s;
  }
  .button_text{
    position: relative;
    z-index: 10;
  }
  .button img{
    position: absolute;
    inset: 0;
    margin: auto;
    pointer-events: none;
    transition: .6s;
    opacity: 0;
  }
  .effil{
    /* width: 18px; */
    height: 80px;
    transform: translate(-65px,-16px);
    z-index: -10;
  }
  .lock{
    height: 70px;
    transform: translate(55px,-16px) rotate(30deg);
    z-index: -10;
  }
  .cctv{
    height: 50px;
    transform: translate(75px,26px) rotate(20deg);
    z-index: -10;
  }
  .button:hover::after{
    opacity: 1;
  }
  .button:hover{
    transform: scale(1.1);
  }
  .button:hover img{
    opacity: 1;
  }
  .button:hover .effil{
    transform: translate(-65px,-40px);
  }
  .button:hover .lock{
    transform: translate(75px,-36px) rotate(30deg);
  }
  .button:hover .cctv{
    transform: translate(95px,40px) rotate(20deg);
  }
  .scrolling-content {
    width: 100%;
    overflow: hidden;
    position: relative;
    scroll-behavior: smooth;
  }
  
  .content {
    display: flex;
    transition: transform 0.5s ease-in-out; /* Adjust the timing and easing as needed */
  }
  .aa{
    display:none;
  }
.dot:hover .aa{
 display: block;
}

/* input  */
.inputbox{
  position: relative;
  width:100%;
  margin-bottom: 20px;
  
}
.inputbox input{
  position: relative;
  border: #E8372C 1px solid;
  border-radius: 6px;
  width: 100%;
  padding: 10px 10px 10px;
  background-color: transparent;
  outline: none;
  color: #f1f1f1;
  font-size: 1rem;
  letter-spacing: 0.05em;
  z-index: 10;
}
textarea{
  outline: none;
  color: #f1f1f1;
  font-size: 1rem;
  letter-spacing: 0.05em;
}
.inputbox span{
  position: absolute;
  left: 0;
  padding: 10px 10px 10px;
  font-size: 1rem;
  color: #8f8f8f;
  pointer-events: none;
  letter-spacing: 0.05em;
  transition: 0.5s;
  z-index: 20;
}
.inputbox input:valid ~ span,
.inputbox input:focus ~ span
{
  color:#E8372C;
  font-size: 0.75em;
  transform: translateX(-10px) translateY(-30px);
}
.inputbox i{
  position: absolute;
  left: 0;bottom: 0;
  width: 100%;
  height: 0px;
  border-radius: 6px;
  background: #E8372C;
  transition: 0.5s;
  pointer-events: none;
  z-index: 9;
}
.inputbox input:valid ~ i,
.inputbox input:focus ~ i{
  height: 45px;
}
.scroll-svg-container {
  width: 100%;
  height: 100vh; /* Adjust to fit your layout */
  overflow: hidden;
  position: relative;
}

.scroll-svg {
  position: absolute;
  top: 0;
  transition: transform 0.3s ease; /* Adjust animation speed as needed */
}
